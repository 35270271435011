.not-activated__wrapper {
    background-color: #F8D7DA;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 29px;
    border-radius: 6px;
    border: 1px solid var(--Red-300, #EA868F);
    background: var(--Red-100, #F8D7DA);
}

.not-activated__wrapper {
    * {
        color: #58151C;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
    }
}

.not-activated__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
    color: #58151C;
    font-family: "Open Sans";
    font-style: normal;
    line-height: normal;

    .title {
        font-size: 14px;
        padding: 0;
        font-weight: 600;

    }

    .subtitle {
        font-size: 12px;
        font-weight: 400;

    }

    .action-text {
        margin-top: 6px;
        color: #58151C;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        width: fit-content;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        cursor: pointer;
    }
}


.not-activated__inner-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}