.modal-digisign {
  @media (max-width: 600px) {
    // padding-left: 6px;
    // width: 25px;
    // height: 28px;
    // font-size: 16pt;
    max-width: 80vw;
  }

  max-width: 60vw;
}

.wrapper-text {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-block: 24px;
  text-align: center;
}

.modal-title {
  margin-bottom: 14px;
  width: 100%;
  color: #09090B;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.modal-subtitle {
  color: rgba(18, 18, 18, 0.60);
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.form-group .no-gutter>.form-group {
  margin-bottom: 0;
}

.header-sponsorship__wrapper {
  gap: 1rem;
  margin-bottom: 40px;
}

.header-sponsorship__text {
  color: #9E968F;
  font-family: "Open Sans";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 18.197px */
}

.modal-body {
  padding-top: 0 !important;
}

.btn-cancel {
  color: #FF7A00 !important;
  border-radius: 8px;
  border: 1px solid var(--color-BLACK10, #D3D0CD) !important;
  background: var(--Neutral-10, #FFF) !important;
  &:hover{
  background-color: white !important;

  }
}
